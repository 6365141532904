// Function to set the text-only cookie
function setTextOnlyCookie( setting ) {

	// Set a cookie with the setting that lasts 31536000 seconds (one year)
	document.cookie =
		'thirdpress_text_only=' +
		setting +
		'; ' +
		'max-age=' +
		'31536000' +
		'; ' +
		'path=' +
		'/' +
		'; ' +
		'domain=' +
		window.location.hostname;
}

// Function to call when a button is clicked
function thirdpress_text_only_toggle ( event ) {

  // Attempt to get an existing cookie
  let textOnlyCookie = document.cookie.match(
    '(^|[^;]+)\\s*thirdpress_text_only\\s*=\\s*([^;]+)'
  );

  // Declare an existing setting if it exists, otherwise mark it as undefined
  let textOnlySetting = textOnlyCookie
    ? textOnlyCookie.pop()
    : 'undefined';

  // If there's no cookie or it's set to show images
  if ( textOnlySetting === 'undefined' || textOnlySetting === 'off' ) {

    // Set the text-only cookie to 'on'
    setTextOnlyCookie( 'on' );

    // Update the class on the content wrapper
    document.querySelector( '#content' ).classList.add( 'text-only' );

    // Loop through all the toggles
    document.querySelectorAll( '.thirdpress-component-text-only-button' ).forEach( toggle => {

      // Update the class on the toggle
      toggle.classList.add( 'text-only' );

      // Update the button text
      // toggle.innerHTML = 'On';

      // Update the aria-label
      toggle.setAttribute( 'aria-label', 'Turn on images' )

    } );

  }

  // Otherwise the cookie is set to on
  else {

    // Set the text-only cookie to 'off'
    setTextOnlyCookie( 'off' );

    // Update the class on the content wrapper
    document.querySelector( '#content' ).classList.remove( 'text-only' );

    // Loop through all the toggles
    document.querySelectorAll( '.thirdpress-component-text-only-button' ).forEach( toggle => {

      // Update the class on the toggle
      toggle.classList.remove( 'text-only' );

      // Update the button text
      // toggle.innerHTML = 'Off';

      // Update the aria-label
      toggle.setAttribute( 'aria-label', 'Turn off images' )

    } );

  }

};



// Add an event listener for clicks on toggles
document
  .querySelectorAll( '.thirdpress-component-text-only-button' )
  .forEach(
    toggle => toggle.addEventListener( 'click', function( toggle ) { thirdpress_text_only_toggle() } )
  );



// When the page has loaded, but without images
document.addEventListener('DOMContentLoaded', () => {

  // Attempt to get an existing cookie
  let textOnlyCookie = document.cookie.match(
    '(^|[^;]+)\\s*thirdpress_text_only\\s*=\\s*([^;]+)'
  );

  // Declare an existing setting if it exists, otherwise mark it as undefined
  let textOnlySetting = textOnlyCookie
    ? textOnlyCookie.pop()
    : 'undefined';

  // If the cookie is set to hide images
  if ( textOnlySetting === 'on' ) {

    // Update the class on the content wrapper
    document.querySelector( '#content' ).classList.add( 'text-only' );

    // Loop through all the toggles
    document.querySelectorAll( '.thirdpress-component-text-only-button' ).forEach( toggle => {

      // Update the class on the toggle
      toggle.classList.add( 'text-only' );

      // Update the button text
      // toggle.innerHTML = 'On';

      // Update the aria-label
      toggle.setAttribute( 'aria-label', 'Turn on images' )

    } );

  }

});

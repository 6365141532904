/* global showOverlay, hideOverlay */

// Set constants for the search DOM nodes
const desktopSearchToggle = document.querySelector( '#desktop-search-toggle' );
const desktopSearchForm = document.querySelector( '#desktop-search-form' );
const desktopSearchInput = document.querySelector( '#desktop-search-input' );

// Bind event listener for a click to the document
document.addEventListener( 'click', function ( event ) {
	detectClickOnNodeOutsideActiveItem( event.target );
} );

// Bind event listener for an escape keydown to the document
document.addEventListener( 'keydown', function ( event ) {
	if ( event.key === 'Escape' ) {
		closeOpenDesktopSubmenu();
		hideSearchForm();
	}
} );

// Function to detect a click outside an active menu item or search form
function detectClickOnNodeOutsideActiveItem( clickedNode ) {
	// Try to get an active menu item
	let activeDesktopSubmenuItemLink = document.querySelector(
		'.desktop-menu-link.has-children[aria-expanded=true]'
	);

	// If there's an active submenu
	if ( activeDesktopSubmenuItemLink ) {
		// If the click is anywhere outside the original menu item
		if ( ! activeDesktopSubmenuItemLink.parentNode.contains( clickedNode ) ) {
			// Close the active submenu
			closeOpenDesktopSubmenu();
		}
	}

	// If this wasn't trigger by the search toggle itself and the search form is active
	if (
		desktopSearchToggle &&
		! desktopSearchToggle.contains( clickedNode ) &&
		desktopSearchToggle.getAttribute( 'aria-expanded' ) === 'true'
	) {
		// If the click is anywhere outside the original menu item
		if ( ! desktopSearchForm.parentNode.contains( clickedNode ) ) {
			// Close the active submenu
			hideSearchForm();
		}
	}
}

// Function to close an active submenu
function closeOpenDesktopSubmenu( clientX ) {
	// Try to get an active menu item
	let activeDesktopSubmenuItemLink = document.querySelector(
		'.desktop-menu-link.has-children[aria-expanded=true]'
	);

	// If there is an active submenu
	if ( activeDesktopSubmenuItemLink ) {
		// Set the aria-expanded attribute to false
		// The menu visibility is controlled by this attribute in CSS
		activeDesktopSubmenuItemLink.setAttribute( 'aria-expanded', 'false' );

		// Update the aria label
		activeDesktopSubmenuItemLink.setAttribute(
			'aria-label',
			activeDesktopSubmenuItemLink
				.getAttribute( 'aria-label' )
				.replace( 'Hide', 'Show' )
		);

		// Hide the overlay
		hideOverlay( 'desktop-overlay' );
	}
}

// Function to hide the search form
function hideSearchForm( clientX ) {
	// If the search toggle is active
	if (
		desktopSearchToggle &&
		desktopSearchToggle.getAttribute( 'aria-expanded' ) === 'true'
	) {
		// Hide the search form
		desktopSearchForm.style.display = 'none';

		// Set the aria-expanded attribute to false
		desktopSearchToggle.setAttribute( 'aria-expanded', 'false' );

		// Blur the search input
		desktopSearchInput.blur();

		// Hide the overlay
		hideOverlay( 'desktop-overlay' );
	}
}

// Loop through all desktop menu links with children
document
	.querySelectorAll( '.desktop-menu-link.has-children' )
	.forEach( function ( desktopMenuItemLink ) {
		// Event listener - when this menu item link is clicked
		desktopMenuItemLink.addEventListener( 'click', function ( event ) {
			// Stop the link being followed and any propagation
			event.preventDefault();
			event.stopPropagation();

			// Store the clicked element as a variable
			let clickedDesktopMenuItemLink = event.target;

			// If the submenu isn't open yet
			if (
				clickedDesktopMenuItemLink.getAttribute( 'aria-expanded' ) === 'false'
			) {
				// Hide the search form
				hideSearchForm();

				// Close any open submenus
				closeOpenDesktopSubmenu();

				// If this menu item is less than halfway across the screen
				if ( clickedDesktopMenuItemLink.getBoundingClientRect().x < ( window.innerWidth / 2 ) ) {

					// Align the submenu to the left
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.left = '1px';
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.right = 'auto';

				// Otherwise it's in the right half
				} else {

					// Align the submenu to the right
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.left = 'auto';
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.right = '0';

				}

				// Set the aria-expanded attribute to true
				// The menu visibility is controlled by this attribute in CSS
				clickedDesktopMenuItemLink.setAttribute( 'aria-expanded', 'true' );

				// Update the aria label
				clickedDesktopMenuItemLink.setAttribute(
					'aria-label',
					clickedDesktopMenuItemLink
						.getAttribute( 'aria-label' )
						.replace( 'Show', 'Hide' )
				);

				// Show the overlay
				showOverlay( 'desktop-overlay', 50 );

				// Otherwise the submenu is already open
			} else {
				// Close the open submenu
				// Send clientX to differentiate between mouse click / key press
				closeOpenDesktopSubmenu( event.clientX );
			}
		} );
	} );

if ( desktopSearchToggle ) {

	// Add an event listener for a click on the desktop search toggle
	desktopSearchToggle.addEventListener( 'click', function ( event ) {
		// If the search form isn't open yet
		if ( desktopSearchToggle.getAttribute( 'aria-expanded' ) === 'false' ) {
			// Close any open submenus
			closeOpenDesktopSubmenu();

			// Show the search form
			desktopSearchForm.style.display = 'block';

			// Set the aria-expanded attribute to true
			desktopSearchToggle.setAttribute( 'aria-expanded', 'true' );

			// Focus on the search input
			desktopSearchInput.focus();

			// Show the overlay
			showOverlay( 'desktop-overlay', 50 );

			// Otherwise the search form is already open
		} else {
			// Hide the search form
			// Send clientX to differentiate between mouse click / key press
			hideSearchForm( event.clientX );
		}
	} );
}

// Stop any initial scroll to a hash
window.scrollTo( 0, 0 );

// Function to scroll to a specific heading
function scrollToHash( linkHref, behavior = 'smooth' ) {

    // Get the heading we should scroll to
    let elementToScrollTo = document.querySelector( linkHref );

    // Set a variable for the eventual scroll position
    let scrollToPosition = 0

    // Add the vertical position of the heading
    scrollToPosition += window.pageYOffset + elementToScrollTo.getBoundingClientRect().top;

    // Remove the height of the mobile header
    if ( mobileHeader = document.querySelector( '#header-mobile' ) ) {
      scrollToPosition -= mobileHeader.getBoundingClientRect().height;
    }

    // Remove the height of the admin bar
    if ( adminBar = document.querySelector( '#wpadminbar' ) ) {
      scrollToPosition -= adminBar.getBoundingClientRect().height;
    }

    // Remove a bit more for spacing
    scrollToPosition -= 30;

    // Scroll to that point
    window.scrollTo( {
      behavior: behavior,
      left: 0,
      top: scrollToPosition,
    } );

}

// Add a listener for the page being loaded
window.addEventListener( 'load', function( event ) {

  // If there's a hash
  if ( window.location.hash ) {

    // Scroll to it
    scrollToHash( window.location.hash, 'instant' );

  }

} );

// Loop through any links that start with a hash
document.querySelectorAll( 'a[ href^="#" ]' ).forEach( function( link ) {

  // Add an event listener for a click
  link.addEventListener( 'click', function( event ) {

    // Stop the link being followed so we can control the scroll
    event.preventDefault();

    // Get the href of the clicked link
    let linkHref = event.currentTarget.getAttribute( 'href' );

    // Scroll to that point
    scrollToHash( linkHref );

    // Add the URL hash
    window.history.pushState( {}, '', window.location.origin + window.location.pathname + linkHref );

  } );

} );

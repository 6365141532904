  // Loop through the headings
  document.querySelectorAll( '.main h2, .main h3' ).forEach( function( heading ) {

    // Get the heading text
    let heading_text = heading.innerHTML;

    // Construct the ID by:
    //  replacing everything except alphanumeric characters, dashes and spaces,
    //  converting to lower case,
    //  and replacing spaces with dashes.
    let heading_id = encodeURIComponent( heading_text.replace( /[^0-9A-Z\-\s]+/gi, '' ).toLowerCase().replaceAll( ' ', '-' ) );

    // Set the ID on the heading
    heading.setAttribute( 'id', heading_id );

  } );
